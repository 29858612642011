import React from 'react'
import { navigate } from "gatsby"


const EmailSubscribe = () => {
    
    return (
        <div className="mt-4 mx-auto w-full sm:w-auto">
            <form 
                name="home-hero-section" 
                onSubmit={ e => {
                e.preventDefault()
                navigate("/subscribe-thank-you/")
                }}
            >
                <input type="hidden" name="form-name" value="home-hero-section" />
                <input 
                className="block sm:inline-block w-full sm:w-auto text-center sm:text-left py-3 sm:pl-4 sm:pr-10 sm:mr-3 mb-3 shadow-sm sm:text-lg border border-slate-300 rounded-md"
                placeholder="Enter your email"
                type="email" 
                name="email"
                >
                </input>
                <button className="block sm:inline-block w-full sm:w-auto py-3 px-8 bg-emerald-500 text-white font-normal rounded sm:mr-1">Sign up</button>
            </form>
        </div>
    )
}

export default EmailSubscribe